@import "../styles/mixin.scss";

.errorPage {
  width: 100vw;
  height: 100vh;
  @include Flex();
  flex-direction: column;
  background: #f5babc;
  .imgContainer {
    width: 280px;
    img {
      width: 100%;
    }
    @media (max-width: 800px) {
      width: 250px;
    }
    @media (max-width: 550px) {
      width: 220px;
    }
  }

  .heading {
    font-size: 3rem;
    margin: 0;
    margin-top: 1rem;
    @media (max-width: 800px) {
      font-size: 2.7rem;
    }
    @media (max-width: 550px) {
      font-size: 2.5rem;
    }
  }
  p {
    font-size: 1.2rem;
    margin: 0;
    margin: 20px 0;
    @media (max-width: 800px) {
      font-size: 1.3rem;
    }
    @media (max-width: 550px) {
      font-size: 1.1rem;
    }
  }
  .redirectBtn {
    border-radius: 40px;
    border: solid black 1.8px;
    height: 60px;
    width: 200px;
    font-size: 1.1rem;
    background-color: white;
    cursor: pointer;
    font-weight: 600;
    @include Flex();

    @media (max-width: 800px) {
      height: 55px;
      width: 170px;
      font-size: 1rem;
    }
    @media (max-width: 500px) {
      height: 40px;
      width: 150px;
      font-size: 0.9rem;
    }
  }
}
