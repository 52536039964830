@mixin Flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin Margin {
  margin: 2rem 7.5rem;
}

$dark-blue: #092f56;
$dark-green: #248277;
